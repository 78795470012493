import "./Slide.scss";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import immagineoomen from "../../assets/pictures/portfolio-oomen.png";
import immagineschirru from "../../assets/pictures/portfolio-card.png";
import foodBlog from "../../assets/pictures/foodBlog.png";
import bandsite from "../../assets/pictures/bandsiteAPI.png";
import coffeShop from "../../assets/pictures/coffeShop.png"; 
import travelSite from "../../assets/pictures/travelSite.png"; 
import yogaTeacher from "../../assets/pictures/yogaWebsite.png"; 
import brainflix from "../../assets/pictures/brainflix.png"; 
import inStock from "../../assets/pictures/instock.png"; 


// import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function Slide() {
  // function alert website undercostruction

  function alertWebsiteUnderCostruction() {
    console.log("this website is undercostruction");
    alert("this website is undercostruction");
  }
  // import carousel code here
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const { t } = useTranslation("global");
  return (
    <div id="portfolio">
      <h1 className="portfolio-title">Portfolio</h1>
      <Carousel responsive={responsive} className="carousel">
        {/*  1. cantina schirru  */}
        <div className="card"> 
          <div className="image-wrapper">
            <img src={immagineschirru} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>CANTINA AGRICOLA SCHIRRU</h3>
            <p className="cards-box__paragraph">{t("wine.message")}</p>

            <a
              href="https://azienda-agricola-schirru.netlify.app/"
              target="_blank"
              rel="noreferrer">
              <button className="button-card">WEBSITE</button>
            </a>
          </div>
        </div>
        {/* 2. oomen webservices */}
        <div className="card">
          <div className="image-wrapper">
            <img src={immagineoomen} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>OOMEN WEB SERVICES</h3>
            <p className="cards-box__paragraph">{t("startup.message")}</p>
            <p>
              <a
                href="https://www.oomen-webservices.com"
                target="_blank"
                rel="noreferrer">
                <button className="button-card">WEBSITE</button>
              </a>
            </p>
          </div>
        </div>
        {/* 3. Gabriela Lujan */}
        <div className="card">
          <div className="image-wrapper">
            <img src={yogaTeacher} alt="yoga teacher" />
          </div>
          <div className="wrapper-card-info">
            <h3>GABRIELA LUJAN</h3>
            <p className="cards-box__paragraph">Website for a yoga teacher who wants to boost her potential online.</p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card">WEBSITE</button>
              </a>
            </p>
          </div>
        </div>
        {/* INSTOCK FULL STACK */}
        <div className="card">
          <div className="image-wrapper">
            <img src={inStock} alt="yoga teacher" />
          </div>
          <div className="wrapper-card-info">
            <h3>INSTOCK - FULL STACK App</h3>
            <p className="cards-box__paragraph">  
            Full-stacK application. We worked in a team using the AGILE methodology, following tickets.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card">GITHUB</button>
              </a>
            </p>
          </div>
        </div>
        {/* BRAINFLIX */}
        <div className="card">
          <div className="image-wrapper">
            <img src={brainflix} alt="yoga teacher" />
          </div>
          <div className="wrapper-card-info">
            <h3>BRAINFLIX VIDEOS</h3>
            <p className="cards-box__paragraph">  It is a video streaming platform. Technologies: React, SASS, npm, API, axios, classes, and server.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card">GITHUB</button>
              </a>
            </p>
          </div>
        </div>
        {/* SARDININAN RECEPIES  */}
        <div className="card">
          <div className="image-wrapper">
            <img src={foodBlog} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>BLOG DOM MANIPULATION</h3>
            <p className="cards-box__paragraph filter-blur">
              Is a startup who allow customers to sell their digital products
              online with instant payment.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">GITHUB</button>
              </a>
            </p>
          </div>
        </div>
        {/* BAND SITE  */}
        <div className="card">
          <div className="image-wrapper">
            <img src={bandsite} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>BAND SITE: using APIs, heroku </h3>
            <p className="cards-box__paragraph filter-blur">
              Is a startup who allow customers to sell their digital products
              online with instant payment.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">
                  GITHUB
                </button>
              </a>
            </p>
          </div>
        </div>
        {/* TRAVEL SITE  */}
        <div className="card">
          <div className="image-wrapper">
            <img src={travelSite} alt="product"/>
          </div>
          <div className="wrapper-card-info">
            <h3>TRAVEL SITE</h3>
            <p className="cards-box__paragraph filter-blur">
            A website about traveling using simple technologies.
            </p>
            <p>
            <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">
                  GITHUB
                </button>
              </a>
            </p>
          </div>
        </div>
        {/* COFFE SHOP */}
        <div className="card">
          <div className="image-wrapper">
            <img src={coffeShop} alt="product"/>
          </div>
          <div className="wrapper-card-info">
            <h3>COFFE SHOP: html CSS</h3>
            <p className="cards-box__paragraph filter-blur">
            It is a website that uses HTML, SASS, and Flexbox.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">
                  GITHUB
                </button>
              </a>
            </p>
          </div>
        </div>
        {/* CARD DA USARE CON FILTER BLUR */}
        <div className="card">
          <div className="image-wrapper">
            <img src={coffeShop} alt="product" className="filter-blur" />
          </div>
          <div className="wrapper-card-info">
            <h3>UNDER COSTRUCTION</h3>
            <p className="cards-box__paragraph filter-blur">
            It is a website that uses HTML, SASS, and Flexbox.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">
                  GITHUB
                </button>
              </a>
            </p>
          </div>
        </div>
      </Carousel>
      ;
    </div>
  );
}

export default Slide;
